import React from "react";
import { Background } from "react-imgix";
import { DateTime } from "luxon";
import { css } from "@emotion/react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import Typography from '../styles/typography';
import { Section, Container, breakpoints, gutters } from "../styles/layouts";
import Layout from "../layouts/default";
import Article from "../components/article";
import { CountDownTimer } from "../components/countdown-timer";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay, faClock, faMapMarkerAlt, faListUl, faBadgeCheck } from '@fortawesome/pro-light-svg-icons';

library.add(faCalendarDay, faClock, faMapMarkerAlt, faListUl, faBadgeCheck);

export default ({ data }) => {
  const activity = data.allWpActivity.edges[0].node;
  const activityDate = DateTime.fromISO(activity.activityCustomField.date);
  const background = data.allWpActivity.edges[0].node.featuredImage.node && data.allWpActivity.edges[0].node.featuredImage.node.mediaDetails;

  return (
    <Layout>
      <SEO title={activity.title} />
      <header css={ActivityHeaderStyle}>
        <div className="overlay">
          <Container>
            <span className="posttype">Activity</span>
            <h1 className="title">{activity.title}</h1>
            { DateTime.local() < activityDate && <CountDownTimer limitDate={activity.activityCustomField.date} /> }
            { DateTime.local() > activityDate && <p className="closed-event-label">このイベントは終了しました</p> }
          </Container>
        </div>
        { background && <Background
          className="background"
          src={`${process.env.GATSBY_WP_IMGIX_URL}/${background.file}`}
          width={background.width}
          height={background.height}
        imgixParams={{ auto: 'compress,enhance,format', sat: 25 }} /> }
      </header>
      <main>
        <div css={ActivityStatusBarStyle}>
          <Container className="status-list">
            <div className="status-item">
              <span className="item-label">
                <FontAwesomeIcon className="icon" icon={["fal", "calendar-day"]} title="schedule" />
                開催日時
              </span>
              <time className="item-value" dateTime={activity.activityCustomField.date}>
                { DateTime.fromISO(activity.activityCustomField.date).toFormat("yyyy年MM月dd日 HH:mm") }
              </time>
            </div>
            <div className="status-item">
              <span className="item-label">
                <FontAwesomeIcon className="icon" icon={["fal", "clock"]} title="schedule" />
                所要時間
              </span>
              <span className="item-value">{ activity.activityCustomField.durationTime }時間</span>
            </div>
            <div className="status-item">
              <span className="item-label">
                <FontAwesomeIcon className="icon" icon={["fal", "map-marker-alt"]} title="schedule" />
                開催場所
              </span>
              <span className="item-value">{ activity.activityCustomField.venue }</span>
            </div>
            <div className="status-item">
              <span className="item-label">
                <FontAwesomeIcon className="icon" icon={["fal", "list-ul"]} title="schedule" />
                参加条件
              </span>
              <span className="item-value">{ activity.activityCustomField.qualifications }</span>
            </div>
            <div className="status-item">
              <span className="item-label">
                <FontAwesomeIcon className="icon" icon={["fal", "badge-check"]} title="schedule" />
                出欠確認
              </span>
              <span className="item-value">{ activity.activityCustomField.attendance }</span>
            </div>
          </Container>
        </div>
        <Section>
          <Container>
            <div css={ActivityDescriptionStyle}>
              <h2 className="title">FreeCompany Event</h2>
              <div className="description" dangerouslySetInnerHTML={{ __html: activity.excerpt }} />
            </div>
            <Article>
              <div dangerouslySetInnerHTML={{ __html: activity.content }} />
            </Article>
          </Container>
        </Section>
      </main>
    </Layout>
  )
}

const ActivityDescriptionStyle = css`
  margin: 0 auto;
  max-width: 60%;

  @media ${breakpoints.handheld} {
    max-width: 90%;
  }

  .title {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-size: 1rem;
    color: #CC244F;
  }

  .description {
    text-align: center;
    font-weight: bold;
    font-size: 1.25rem;
    word-break: keep-all;
    line-height: 1.5;

    @media ${breakpoints.handheld} {
      word-break: normal;
    }
  }
`;

const ActivityStatusBarStyle = css`

  background: #fff;
  border-bottom: 1px solid #EAEDEE;

  .status-list {
    display: flex;
    justify-content: space-between;

    @media ${breakpoints.handheld} {
      flex-direction: column;
    }
  }

  .status-item {
    padding: 1rem;
  }

  .icon {
    margin-right: .75em;
    width: .75rem;
    height: .75rem;
  }

  .item-label {
    display: block;
    font-weight: bold;
    font-size: .75rem;
    letter-spacing: .1em;
  }

  .item-value {
    display: block;
    font-size: .825rem;
  }
`;

export const query = graphql`
  query($slug: String!) {
    allWpActivity(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          content
          excerpt
          slug
          uri
          date(formatString: "MM-DD-YYYY")
          author {
            node {
              name
            }
          }
          activityCustomField {
            date
            venue
            qualifications
            durationTime
            attendance
          }
          featuredImage {
            node {
              id
              mediaItemUrl
              mediaDetails {
                file
                height
                width
              }
            }
          }
        }
      }
    }
  }
`;




const ActivityHeaderStyle = css`
  position: relative;


  background: #4D4F50;

  .overlay {
    position: relative;
    z-index: 1;
    box-sizing: border-box;

    padding-top: calc(7rem + ${gutters.console.row});
    padding-bottom: calc(${gutters.console.row});

    text-align: center;
    text-shadow:
      0 0 1px rgba(0,0,0,.2),
      0 0 4px rgba(0,0,0,.1);

    @media ${breakpoints.handheld} {
      padding-top: calc(5rem + ${gutters.handheld.row});
      padding-bottom: calc(${gutters.handheld.row});
    }

    background-color: rgba(0,0,0,.5);
  }

  .background {
    position: absolute;
    display: block;
    z-index: 0;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }


  .posttype {
    display: block;
    margin-bottom: 1rem;
    min-height: 1.5em;

    ${Typography.h6};
    text-align: center;
    font-weight: bold;
    letter-spacing: .1em;
    font-size: 1rem;
    color: #F6486C;
    text-transform: uppercase;

  }

  .title {
    margin: 0 auto calc(${gutters.console.row} / 2) auto;
    max-width: 70%;
    ${Typography.h1};
    color: #fff;
    text-align: center;
    word-break: keep-all;

    @media ${breakpoints.handheld} {
      margin-bottom: calc(${gutters.handheld.row} / 2);
      max-width: 100%;
      word-break: normal;
    }
  }

  .closed-event-label {
    margin: 0;
    display: inline-block;
    padding: .5rem 1rem;
    color: #fff;
    text-align: center;
    font-weight: bold;
    ${Typography.p};

    border: 2px solid #fff;
    border-radius: 3px;

    background: rgba(0,0,0,.2);
  }
`;

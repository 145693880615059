import React, { useState, useEffect, useRef } from "react";
import { DateTime, Interval } from "luxon";
import { css } from "@emotion/react";

export const CountDownTimer = ({ limitDate }) => {
  const [viewCounts, setViewCounts] = useState({
    days: null,
    hours: null,
    minutes: null,
    seconds: null
  });
  const intervalKey = useRef();
  const targetDatetime = DateTime.fromISO(limitDate);

  const intervalUpdate = () => {
    if (!intervalKey.current) return false;

    const currentDuration = Interval.fromDateTimes(DateTime.local(), targetDatetime).toDuration(['days', 'hours', 'minutes', 'seconds']).toObject();

    const days = currentDuration.days;
    const hours = currentDuration.hours;
    const minutes = currentDuration.minutes;
    const seconds = currentDuration.seconds;

    setViewCounts({
      days: (days > 0) ? Math.floor(days) : 0,
      hours: (hours > 0) ? Math.floor(hours) : 0,
      minutes: (minutes > 0) ? Math.floor(minutes) : 0,
      seconds: (seconds > 0) ? Math.floor(seconds) : 0
    });
  }

  const Column = ({ label, value }) => {
    const padCount = (value != null && value >= 0) ? value.toString().padStart(2, '0') : '--';

    return (
      <div className="column">
        <span className="column-label">{ label }</span>
        <span className="column-value">{ padCount }</span>
      </div>
    );
  }

  useEffect(() => {
    intervalKey.current = setInterval(intervalUpdate, 1000);

    return () => {
      clearInterval(intervalKey.current);
      intervalKey.current = null;
    };
  }, []);

  return (
    <div css={CountDownTimerStyle}>
      <Column label="days" value={viewCounts.days} />
      <Column label="hours" value={viewCounts.hours} />
      <Column label="minutes" value={viewCounts.minutes} />
      <Column label="seconds" value={viewCounts.seconds} />
    </div>
  );
}

const CountDownTimerStyle = css`
  display: flex;
  justify-content: center;

  .column {
    display: flex;
    margin: 0 .25rem;
    flex-direction: column-reverse;

    width: 4rem;

    color: #fff;

    .column-label {
      display: block;

      line-height: 1;
      text-align: center;
      font-size: .825em;
    }
    .column-value {
      display: block;
      margin-bottom: .5rem;

      line-height: 1;
      text-align: center;
      font-weight: bold;
      font-size: 2rem;
    }
  }
`;
